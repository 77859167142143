/*
This file contains all the api interaction with profile apis
getting user profile, getting user profile by id, username, ensName, address
Any mution also goes here, which invalidates the cache on a successful response

*/

import { signedRequest, signedApiOtherRequest, signedApiRequest } from "./api";
import { stripTags } from "utils/misc";
import { ApiEndpoints, Chart } from "utils/constants";
export const getIdentity = async () => {
  return signedApiRequest({
    method: "get",
    path: "my_identity",
  });
};

export const verifyWalletApi = async ({
  tokenSerialized,
  signature,
  walletType,
}) => {
  return signedApiRequest({
    method: "post",
    path: "authorize_address",
    bodyText: JSON.stringify({
      token_serialized: tokenSerialized,
      signature: signature,
      wallet_type: walletType,
    }),
  });
};

/* src/components/V2/NewOnboarding/profileApi.js */
export const updateProfileDetails = async (profileDetails) => {
  return signedApiRequest({
    method: "post",
    path: "onboarding_update_profile_details",
    bodyText: JSON.stringify(profileDetails),
  });
};

export const getProfileData = async ({ identifier }) => {
  // TODO Change this to get profile api
  // should be okay not to have a signed API request here
  return signedRequest({
    method: "post",
    path: "/api/0xppl/get_profile_details",

    bodyText: JSON.stringify({
      identifier: identifier,
    }),
  });
};

export const getMutualFollowers = async ({ identityId }) => {
  return signedRequest({
    method: "post",
    path: "/api/0xppl/get_mutual_followers",

    bodyText: JSON.stringify({
      identifier: identityId,
    }),
  });
};

export const getNetworthData = async ({ identifier }) => {
  return signedRequest({
    method: "post",
    path: "/api/0xppl/get_net_worth",

    bodyText: JSON.stringify({
      identifier: identifier,
    }),
  });
};

export const updateNetWorthPreferences = async ({ sources, identifier }) => {
  return signedRequest({
    method: "post",
    path: "/api/0xppl/update_net_worth_preferences",
    bodyText: JSON.stringify({
      sources,
      identity_id: identifier,
    }),
  });
};

export const getBundleData = async ({ identifier, viewerPerspectiveId }) => {
  const payload = viewerPerspectiveId
    ? {
        identifier: identifier,
        identity_perspective: viewerPerspectiveId,
        primary_bundle_only: true,
      }
    : { identifier: identifier };
  return signedRequest({
    method: "post",
    path: "/api/0xppl/get_bundle_info",

    bodyText: JSON.stringify(payload),
  });
};
export const getDeployedContracts = async ({ identifier }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_contracts_deployed",

    bodyText: JSON.stringify({ identifier }),
  });

  return response.data;
};

export const getNotifications = async ({ viewerPerspectiveId }) => {
  return signedRequest({
    method: "post",
    path: "/api/fcm/get_notifications",
    bodyText: JSON.stringify({
      identity_perspective: viewerPerspectiveId,
    }),
  });
};

export const getMyWallets = async ({ identifier }) => {
  return signedRequest({
    method: "post",
    path: "/api/0xppl/get_bundle_info",
    bodyText: JSON.stringify({
      identifier: identifier,
      primary_bundle_only: true,
    }),
  });
};

export const copyBundle = async ({ identifier, user }) => {
  return signedRequest({
    method: "post",
    path: "/api/0xppl/duplicate_user_bundle",
    bodyText: JSON.stringify({
      identifier: identifier,
      user: user,
    }),
  });
};

export const getActivitiesSummary = async ({ identifier }) => {
  return signedRequest({
    method: "post",
    path: "/api/v4/get_graph_profile_summary",
    bodyText: JSON.stringify({
      identifier: identifier,
    }),
  });
};

export const getTokenHoldings = async ({ identifier, activeChainId }) => {
  return signedRequest({
    method: "post",
    path: "/api/0xppl/get_token_holdings",
    bodyText: JSON.stringify({
      identifier: identifier,
      chain_id: activeChainId,
    }),
  });
};

export const prefetchNetworth = async ({ identifier, queryClient }) => {
  await queryClient.prefetchQuery({
    queryKey: ["networth", identifier],
    queryFn: () => getNetworthData({ identifier }),
  });
};

export const prefetchActivitySummary = async ({ identifier, queryClient }) => {
  await queryClient.prefetchQuery({
    queryKey: ["activitiesSummary", identifier],
    queryFn: () => getActivitiesSummary({ identifier }),
  });
};

export const getRelatedWallets = async ({ identifier }) => {
  const response = await signedRequest({
    method: "post",
    path: "/api/v4/get_related_wallets",
    bodyText: JSON.stringify({
      identifier: identifier,
    }),
  });

  return response.data;
};

export const getProtocolHoldings = async ({ identifier, slowPath = false }) => {
  const resp = await signedRequest({
    method: "post",
    path: `/api/0xppl/get_protocol_token_holdings?identifier=${identifier}&slow_path=${slowPath}`,
  });
  return resp?.data?.data;
};

export const fetchNftHoldings = async ({ identifier }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/v4/get_wallet_nft_holdings?identifier=${identifier}`,
  });
  return response;
};

export const unFollow = async (identityId) =>
  await signedApiRequest({
    method: "post",
    path: "unfollow_identity",
    bodyText: JSON.stringify({
      identity_id: identityId,
    }),
  });

export const followIdentity = async (identityId) =>
  await signedApiRequest({
    method: "post",
    path: "follow_identity",
    bodyText: JSON.stringify({ identity_id: identityId }),
  });

export const followAddress = async ({
  address,
  nickname = "",
  bio = "",
  twitter = "",
  telegram = "",
  linkedIn = "",
  github = "",
  lens = "",
  farcaster = "",
  sync_with_twitter = false,
}) =>
  await signedApiRequest({
    method: "post",
    path: "follow_identity",
    bodyText: JSON.stringify({
      identity_info: {
        addresses: [{ address: address, reason: "" }],
        nickname: nickname,
        bio: bio.length ? stripTags(bio) : "",
        socials: {
          twitter: twitter ?? "",
          telegram: telegram ?? "",
          linkedin: linkedIn ?? "",
          github: github ?? "",
          lens: lens ?? "",
          farcaster: farcaster ?? "",
        },
        sync_with_twitter,
      },
    }),
  });

export const editProfile = async ({
  identityId,
  nickname = "",
  bio = "",
  twitter = "",
  telegram = "",
  linkedIn = "",
  github = "",
  lens = "",
  farcaster = "",

  sync_with_twitter = false,
}) =>
  await signedApiRequest({
    method: "post",
    path: "follow_identity",
    bodyText: JSON.stringify({
      identity_id: identityId,
      identity_info: {
        nickname: nickname,
        bio: bio.length ? stripTags(bio) : "",
        socials: {
          twitter: twitter ?? "",
          telegram: telegram ?? "",
          linkedin: linkedIn ?? "",
          github: github ?? "",
          lens: lens ?? "",
          farcaster: farcaster ?? "",
        },
        sync_with_twitter,
      },
    }),
  });

export const updateMyProfile = async ({
  username = null,
  name,
  bio = null,
  email,
  twitter,
  linkedIn,
  github,
  telegram,
  lens,
  farcaster,
  onboarding = {},
}) => {
  const socials = {};
  if (twitter) {
    socials.twitter = twitter;
  }
  if (linkedIn) {
    socials.linkedin = linkedIn;
  }
  if (telegram) {
    socials.telegram = telegram;
  }
  if (github) {
    socials.github = github;
  }
  if (lens) {
    socials.lens = lens;
  }
  if (farcaster) {
    socials.farcaster = farcaster;
  }
  if (email) {
    socials.email = email;
  }

  const profile = {
    username,
    nickname: name,
    socials,
    addresses: [],
  };

  if (bio != null) {
    profile.bio = bio;
  }

  return await signedApiRequest({
    method: "post",
    path: "update_my_profile",
    bodyText: JSON.stringify({
      profile,
      ...onboarding,
    }),
  });
};

export const getTwitterProfile = async (twitter, signal) => {
  return await signedApiOtherRequest({
    method: "post",
    path: "twitter/get_twitter_profile",
    bodyText: JSON.stringify({
      twitter_username: twitter,
    }),
    signal,
  });
};

export const bundleAddress = async ({ identityId, address, reason }) => {
  const data = {
    identity_id: identityId,
    add_addresses: [
      {
        reason,
        address,
      },
    ],
  };
  return await signedApiRequest({
    method: "post",
    path: "add_remove_addresses",
    bodyText: JSON.stringify(data),
  });
};
export const profileQueryKey = ({ identifier }) => [`profile-${identifier}`];

// export const getActivities = async ({ identifier, activeChainId }) => {
//   TODO;
// };

export const addRemoveAddressFromBundle = async ({
  address,
  reason,
  identity_id,
  is_private,
  group_ids = [],
  addOrRemove = "Add",
}) => {
  let data = { identity_id: identity_id };

  if (addOrRemove === "Add") {
    data = {
      ...data,
      add_addresses: [{ address, reason, is_private, group_ids }],
    };
  } else {
    data = {
      ...data,
      remove_addresses: [{ address, reason, is_private }],
    };
  }

  const resp = await signedApiRequest({
    method: "post",
    path: "add_remove_addresses",
    bodyText: JSON.stringify(data),
  });

  // refetch();
  return resp;
};

export const discardWalletRelationship = async ({ address_a, address_b }) => {
  const data = {
    address_a: address_a,
    address_b: address_b,
  };

  const resp = await signedApiRequest({
    path: "discard_wallet_relationship",
    method: "post",
    bodyText: JSON.stringify(data),
  });
  return resp;
};

export const prefetchBundleInfo = async ({ identifier, queryClient }) => {
  await queryClient.prefetchQuery({
    queryKey: ["bundle", identifier],
    queryFn: () => getBundleData({ identifier }),
  });
};

export const getProfileGraphData = async ({
  identifier,
  forProtocolTreasury,
  tab,
  duration,
  selectedChains = [],
  selectedTokens = [],
}) => {
  if (forProtocolTreasury == null) {
    return {};
  }
  const endpoint = forProtocolTreasury
    ? "/api/v4/get_protocol_portfolio_over_time"
    : "/api/v4/get_portfolio_over_time";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      identifier,
      tab,
      duration,
      selectedChains,
      selectedTokens,
    }),
  });
  return response;
};

export const getNetworthGraphData = async ({
  duration = Chart.DURATION_DAYS["7D"],
  identifier,
}) => {
  const endTimestamp = Math.floor(new Date().getTime() / 1000);
  const startTimestamp =
    duration === "ALL" ? 365 : endTimestamp - duration * 24 * 60 * 60;
  const endpoint = `/api/v4/get_portfolio_over_time?start_ts=${startTimestamp}&end_ts=${endTimestamp}`;
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      identifier,
    }),
  });
  return response.data;
};

export const getFollowersFollowingList = async ({
  isFollowers,
  identityId,
}) => {
  const path = isFollowers
    ? ApiEndpoints.GET_FOLLOWERS_LIST
    : ApiEndpoints.GET_FOLLOWING_LIST;
  const response = await signedRequest({
    method: "post",
    path: path,
    bodyText: JSON.stringify({
      identity_id: identityId,
    }),
  });
  return response;
};

export const updateNotificationStatus = async ({ identityId, preference }) => {
  const response = await signedRequest({
    method: "post",
    path: ApiEndpoints.UPDATE_NOTIFICATION_PREFERENCE,
    bodyText: JSON.stringify({
      followee_identity_id: identityId,
      notification_preference: preference,
    }),
  });
  return response;
};

export const getFiltersData = async ({ identifiers }) => {
  var path = ApiEndpoints.GET_FILTERS_DATA;
  //here identifier will be a list make path with comma separated
  if (identifiers) {
    path = path + "?identifiers=" + identifiers;
  }
  const response = await signedRequest({
    method: "post",
    path: path,
  });
  return response;
};

export const getSupportedChains = async () => {
  const res = await signedRequest({
    method: "get",
    path: "/api/v4/get_supported_chains",
  });
  return res?.data?.data;
};

export const fetchTokenHoldings = async ({
  endpoint = "/api/0xppl/get_token_holdings",
  identifier,
  maxHoldings,
  slowPath = false,
}) => {
  var endpointModified = `${endpoint}?identifier=${identifier}&slow_path=${slowPath}`;
  if (maxHoldings) {
    endpointModified += `&max_holdings=${maxHoldings}`;
  }
  const response = await signedRequest({
    method: "post",
    path: endpointModified,
  });
  const data = response.data.data;
  return {
    holdings: data?.combined_holdings?.holdings,
    holdings_by_address: data?.holdings_by_address,
    chain_profiles: data?.chain_profiles,
    pusher_event: data?.pusher_event,
    last_updated_at: data?.combined_holdings?.last_updated_at,
    ...(data?.combined_holdings?.last_updated_at > 0
      ? {
          total_usd_value:
            data?.combined_holdings?.total_usd_value?.display_value,
        }
      : {}),
  };
};

export const fetchRecentOrTopHoldings = async ({
  endpoint = "/api/v4/get_top_holdings",
  identifier,
}) => {
  const response = await signedRequest({
    method: "post",
    path: `${endpoint}?identifier=${identifier}`,
  });
  return response.data.data;
};

export const getUserProfileOboardingStatus = async () => {
  const endpoint = "/api/v4/get_user_profile_steps_status";
  const response = await signedRequest({
    method: "get",
    path: endpoint,
  });
  return response.data.data;
};

export const getStaticConfigs = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/0xppl/get_static_configs",
  });
  return response.data.data;
};

export const getPrivateWalletsSharingRequest = async ({ toIdentityId }) => {
  const endpoint = "/api/0xppl/get_private_wallets_sharing_request_info";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      requested_to_identity_id: toIdentityId,
    }),
  });
  return response.data.data;
};

export const sendPrivateWalletsSharingRequest = async ({
  toIdentityId,
  walletAddresses,
}) => {
  const endpoint = "/api/0xppl/send_private_wallets_sharing_request";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      requested_to_identity_id: toIdentityId,
      addresses_to_share: walletAddresses,
    }),
  });
  return response.data;
};

export const cancelPrivateWalletsSharingRequest = async ({ toIdentityId }) => {
  const endpoint = "/api/0xppl/cancel_private_wallets_sharing_request";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      requested_to_identity_id: toIdentityId,
    }),
  });
  return response.data.data;
};

export const acceptPrivateWalletsSharingRequest = async ({
  toIdentityId,
  walletAddresses,
}) => {
  const endpoint = "/api/0xppl/accept_private_wallets_sharing_request";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      requested_to_identity_id: toIdentityId,
      addresses_to_share: walletAddresses,
    }),
  });
  return response.data.data;
};

export const getReceivedConnectionRequests = async () => {
  const endpoint = "/api/0xppl/get_received_connection_requests";
  const response = await signedRequest({
    method: "get",
    path: endpoint,
  });
  return response.data.data;
};

export const getSentConnectionRequests = async () => {
  const endpoint = "/api/0xppl/get_sent_connection_requests";
  const response = await signedRequest({
    method: "get",
    path: endpoint,
  });
  return response.data.data;
};

export const getActiveConnections = async () => {
  const endpoint = "/api/0xppl/get_active_connections";
  const response = await signedRequest({
    method: "get",
    path: endpoint,
  });
  return response.data.data;
};

export const updateNotificationPreference = async ({
  identityId,
  notificationPreference,
}) => {
  const endpoint = "/api/0xppl/update_notification_preference";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      identity_id: identityId,
      notification_preference: notificationPreference,
    }),
  });
  return response.data.data;
};

export const createWalletGroup = async ({ name }) => {
  const endpoint = "/api/0xppl/create_wallet_group";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      name,
    }),
  });
  return response.data.data;
};

export const editWalletGroup = async ({ groupId, newGroupName }) => {
  const endpoint = "/api/0xppl/edit_wallet_group";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      group_id: groupId,
      new_group_name: newGroupName,
    }),
  });
  return response.data.data;
};

export const deleteWalletGroup = async ({ groupId }) => {
  const endpoint = "/api/0xppl/delete_wallet_group";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      group_id: groupId,
    }),
  });
  return response.data.data;
};

export const getInviteDetails = async ({ identifier }) => {
  const endpoint = "/api/access_control/get_invite_details";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      identifier,
    }),
  });
  return response.data.data;
};

export const getInviteGraph = async ({ identifier }) => {
  const endpoint = "/api/access_control/get_invite_graph";
  const response = await signedRequest({
    method: "post",
    path: endpoint,
    bodyText: JSON.stringify({
      identifier,
    }),
  });
  return response.data.data;
};

export const getTopNFTHoldings = async ({ identifier }) => {
  const endpoint = "/api/v4/get_top_nft_holdings";
  const response = await signedRequest({
    method: "post",
    bodyText: JSON.stringify({ identifier }),
    path: endpoint,
  });
  return response.data.data;
};

export const getMutualHoldings = async ({ identifier }) => {
  const endpoint = "/api/0xppl/get_mutual_holdings";
  const response = await signedRequest({
    method: "post",
    bodyText: JSON.stringify({ identifier }),
    path: endpoint,
  });
  return response.data.data;
};
