import { CircularProgress, ClickAwayListener } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import classes from "./FollowEditButton.module.css";
import PPLxHoverCard from "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
import FollowForm from "./FollowForm/FollowForm";
import {
  bundleAddress,
  editProfile,
  followAddress,
  followIdentity,
  unFollow,
  updateMyProfile,
} from "api/profile.api";
import { GlobalContext } from "contextStates/Global";
import { AuthContext } from "contextStates/AuthContext";
import { PencilSimple } from "@phosphor-icons/react";
import {
  IconWrapper,
  TransparentButton,
  CustomRow,
} from "components/UI/Components/Components";
import { useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { Plus } from "@phosphor-icons/react";
import { trackEvent } from "utils/event_tracking";

/*

Takes profile object as input 
Displays follow button if profile is not followed, else Unfollow Button
Displays edit button if profile is followed and edit is true
Profile Should contain is_following


*/

const FollowEditButton = ({
  name = null,
  twitter = null,
  bio = null,
  followed,
  onUpdateCallback,
  address,
  identityId,
  showEdit = true,
  disabled,
  refetchFeedOnFollowOrUnfollow = false,
  followDirectly = false,
  customTextClass,
  children,
  socials,
  small = false,
  minHeight = "32px",
  minWidth = "90px",
  fullWidth = false,
  darkButton = false,
}) => {
  const history = useHistory();

  const [followedState, setFollowedState] = useState(followed);
  const [isUpdating, setIsUpdating] = useState(false);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const [isEditMode, setIsEditMode] = useState(false);

  const { isUserLoggedIn, setShowLoginPopup, identityDetails } =
    useContext(AuthContext);

  const ownProfile =
    isUserLoggedIn && identityDetails.current?.identity?.id === identityId;

  const [showCard, setShowCard] = useState(
    ownProfile && history.location.search?.includes("openEditProfile")
  );

  useEffect(() => {
    setFollowedState(followed);
  }, [followed]);

  const queryClient = useQueryClient();
  const refetchFeed = useCallback(() => {
    queryClient.refetchQueries({
      queryKey: ["my_feed"],
    });
  }, [queryClient]);
  const onFollowPress = useCallback(
    (event) => {
      event?.stopPropagation();
      trackEvent("follow_clicked", { user_id_followed: identityId });

      if (!isUserLoggedIn) {
        setShowLoginPopup(true);
        return;
      }

      if (identityId) {
        setIsUpdating(true);
        followIdentity(identityId)
          .then((data) => {
            setFollowedState(true);
            setIsUpdating(false);
            if (refetchFeedOnFollowOrUnfollow) {
              refetchFeed();
            }
            onUpdateCallback?.();
          })
          .catch((err) => {
            setIsUpdating(false);
            handleErrorSnackbar(err, "Failed to follow, try again!");
          });
      } else {
        setIsUpdating(true);

        if (followDirectly) {
          followAddress({
            address,
            nickname: name,
            bio: bio ?? "",
            twitter,
            sync_with_twitter: true,
          })
            .then((resp) => {
              setFollowedState(true);
              setIsUpdating(false);
              onUpdateCallback?.(resp.data.data);
            })
            .catch((err) => {
              setIsUpdating(false);
              handleErrorSnackbar(err, "Failed to follow, try again!");
            });
        } else {
          setShowCard(true);
        }
      }
    },
    [
      isUserLoggedIn,
      identityId,
      setShowLoginPopup,
      refetchFeedOnFollowOrUnfollow,
      refetchFeed,
      handleErrorSnackbar,
      onUpdateCallback,
      address,
      name,
      bio,
      twitter,
      followDirectly,
    ]
  );

  const onUnFollowPress = useCallback(
    (event) => {
      event?.preventDefault();
      event?.stopPropagation();
      setIsUpdating(true);
      trackEvent("unfollow_clicked", { user_id_unfollowed: identityId });
      unFollow(identityId)
        .then(() => {
          setFollowedState(false);
          setIsUpdating(false);
          if (refetchFeedOnFollowOrUnfollow) {
            refetchFeed();
          }
          onUpdateCallback?.();
        })
        .catch((err) => {
          setIsUpdating(false);
          handleErrorSnackbar(err, "Failed to unfollow, try again!");
        });
    },
    [
      identityId,
      refetchFeedOnFollowOrUnfollow,
      onUpdateCallback,
      refetchFeed,
      handleErrorSnackbar,
    ]
  );

  const onAddressFollow = useCallback(
    ({
      nickname,
      bio,
      twitter,
      telegram,
      linkedIn,
      github,
      lens,
      farcaster,
      useDetails,
    }) => {
      followAddress({
        address,
        nickname,
        bio,
        twitter,
        telegram,
        linkedIn,
        github,
        lens,
        farcaster,
        sync_with_twitter: useDetails,
      })
        .then((resp) => {
          setShowCard(false);
          setIsUpdating(false);
          onUpdateCallback?.(resp.data.data);
        })
        .catch((err) => {
          handleErrorSnackbar(err, "Error while following, try again!");
        });
    },
    [address, handleErrorSnackbar, onUpdateCallback, setIsUpdating]
  );

  const onProfileEdit = useCallback(
    ({
      nickname,
      bio,
      twitter,
      telegram,
      linkedIn,
      github,
      lens,
      farcaster,
      useDetails,
    }) => {
      const promise = ownProfile
        ? updateMyProfile({
            name: nickname,
            bio,
            twitter,
            telegram,
            linkedIn,
            github,
            lens,
            farcaster,
          })
        : editProfile({
            identityId,
            nickname,
            bio,
            twitter,
            telegram,
            linkedIn,
            github,
            lens,
            farcaster,
            sync_with_twitter: useDetails,
          });
      promise
        .then((resp) => {
          setShowCard(false);
          if (ownProfile) {
            onUpdateCallback(resp.data.data.identity);
          } else {
            onUpdateCallback(resp.data.data);
          }
        })
        .catch((err) => {
          handleErrorSnackbar(err, "Error while following, try again!");
        });
    },
    [identityId, handleErrorSnackbar, onUpdateCallback, ownProfile]
  );

  const onAddToBundle = useCallback(
    (identityId) => {
      return bundleAddress({
        identityId,
        address,
        reason: "",
      })
        .then((resp) => {
          setShowCard(false);
          onUpdateCallback(resp.data.data.identity);
          queryClient.invalidateQueries([["bundle", identityId]]);
        })
        .catch((err) => {
          handleErrorSnackbar(err, "Error adding address to profile");
        });
    },

    [address, handleErrorSnackbar, onUpdateCallback, queryClient]
  );

  const onEdit = useCallback((event) => {
    event?.stopPropagation();
    trackEvent("edit_profile_clicked");
    setIsEditMode(true);
    setShowCard(true);
  }, []);

  if (disabled) {
    return null;
  }
  // TODO API calls should be in profile.api.js, so that it can invalidat they query cache

  if (showCard) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setShowCard(false);
          setIsUpdating(false);
        }}
        className={classes.follow_edit_button}>
        <div>
          <PPLxHoverCard
            open={showCard}
            content={
              <FollowForm
                onAddressFollow={onAddressFollow}
                onAddToBundle={onAddToBundle}
                name={name}
                bio={bio}
                isEditMode={isEditMode}
                onProfileEdit={onProfileEdit}
                socials={socials}
                ownProfile={ownProfile}
              />
            }
            placement="bottom">
            <div
              className={classes.spinner_container}
              style={{ minWidth: fullWidth ? "100%" : "auto" }}>
              <CircularProgress color="inherit" size={12} />
            </div>
          </PPLxHoverCard>
        </div>
      </ClickAwayListener>
    );
  }

  if (isUpdating) {
    return (
      <div className={classes.follow_edit_button}>
        <div
          className={classes.spinner_container}
          style={{ minWidth: fullWidth ? "100%" : "auto" }}>
          <CircularProgress color="inherit" size={12} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.follow_edit_button}>
      {!ownProfile && followedState && showEdit && (
        <IconWrapper color="var(--elevation-2)" noBorder>
          <PencilSimple
            onClick={(event) => onEdit(event)}
            fontSize="18px"
            color="var(--text-2)"
          />
        </IconWrapper>
      )}
      <div
        className={classes.button}
        style={{ minHeight, minWidth: fullWidth ? "100%" : "auto" }}
        onClick={(event) =>
          ownProfile
            ? onEdit(event)
            : followedState
              ? onUnFollowPress(event)
              : onFollowPress(event)
        }>
        <FollowButton
          followedState={followedState}
          ownProfile={ownProfile}
          customTextClass={customTextClass}
          children={children}
          small={small}
          minHeight={minHeight}
          minWidth={minWidth}
          fullWidth={fullWidth}
          darkButton={darkButton}
        />
      </div>
    </div>
  );
};

const FollowButton = ({
  followedState,
  ownProfile,
  customTextClass,
  children,
  small = false,
  minHeight = "32px",
  minWidth = "auto",
  fullWidth,
  darkButton,
}) => {
  const [hovereredState, setHoveredState] = useState(false);
  let buttonText = followedState ? (
    hovereredState ? (
      "Unfollow"
    ) : (
      "Following"
    )
  ) : (
    <CustomRow gap="4px" alignItems="center" justifyContent="center">
      {!small && <Plus size="14" weight="bold" />}
      <span>Follow</span>
    </CustomRow>
  );

  if (ownProfile) {
    buttonText = "Edit Profile";
  }
  if (children) {
    buttonText = children;
  }
  let buttonClass = "";

  if (ownProfile && hovereredState) {
    buttonClass = classes.follow;
  }

  if (!ownProfile && !followedState) {
    if (hovereredState) {
      buttonClass = classes.follow_hovered;
    } else {
      buttonClass = classes.follow;
    }
  }

  if (!ownProfile && followedState && hovereredState) {
    buttonClass = classes.unfollow;
  }

  if (children) {
    return (
      <TransparentButton
        style={{
          ...(!followedState ? { pointerEvents: "none" } : {}),
          minHeight,
          minWidth: fullWidth ? "100%" : minWidth,
        }}>
        {children}
      </TransparentButton>
    );
  }
  return (
    <button
      style={{
        minWidth: fullWidth ? "100%" : minWidth,
      }}
      className={`${classes.follow_button} ${
        classes.button
      } ${buttonClass} ${customTextClass} ${small && classes.smallButton} ${fullWidth ? classes.fullWidth : ""} ${darkButton ? classes.darkButton : ``}`}
      onMouseOver={() => {
        setHoveredState(true);
      }}
      onMouseOut={() => {
        setHoveredState(false);
      }}>
      {buttonText}
    </button>
  );
};

export default FollowEditButton;
