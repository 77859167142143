import Web3 from "web3";
import defaultProfileImage from "assets/webp/profile_picture.webp";
import walletIcon from "assets/svg/wallet-icon.svg";
import { chainMetaData } from "api/static_data";
import { flatMap, map, uniq, uniqBy } from "lodash";
import ProfileHoverCard from "components/V2/Profile/ProfileHoverCard/ProfileHoverCard";
import { Link } from "react-router-dom";
import ValueType from "components/ValueType/ValueType";
import { remapAssets } from "./assets";
import { getGroupedActivity } from "api/feed.api";
import { QueryKeyConstants } from "./constants";
import * as Sentry from "@sentry/react";
import { IMAGE_TYPES } from "components/UI/Image";
import { MobileOSTypes } from "./constants";

export const shortStartingAddress = (
  address,
  { digitsInAddress = 8, isRemove0X = false } = {}
) => {
  if (!address) return "";
  if (address.slice(0, 2) !== "0x") {
    // only cut first x chars
    return address.slice(0, digitsInAddress);
  }
  let firstTwoDigits = "";
  if (!isRemove0X) {
    firstTwoDigits = address.slice(0, 2);
  }
  const uppercaseDigits = address
    .slice(2, isRemove0X ? digitsInAddress + 2 : digitsInAddress)
    .toUpperCase();

  return `${firstTwoDigits}${uppercaseDigits}`;
};

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const handleErrorImg = (event) => {
  if (event) {
    event.stopPropagation();
    event.target.src = defaultProfileImage;
  }
};

export const openUrlClickHandler = (event, url) => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
  if (url.length)
    return () => {
      window.open(url);
    };
};

export const getTrimedAddress = (str, initial = 4) => {
  if (str && str.length > 10) {
    return `${str.substr(0, initial)}...${str.substr(str.length - 4)}`;
  }
  return str;
};

export const getExtensionFromUrl = (url) => {
  if (url?.length) {
    return url?.substring(url.lastIndexOf(".")).toLowerCase();
  }
};

export const getTypeFromUrl = (url) => {
  if (url?.length) {
    const mediaType = getExtensionFromUrl(url);
    let type;
    if ([".gltf", ".glb"].includes(mediaType)) {
      type = "model";
    } else if ([".jpg", ".png", ".jpeg"].includes(mediaType)) {
      type = "image";
    } else if ([".mp4", ".avi"].includes(mediaType)) {
      type = "video";
    } else {
      type = "image";
    }
    return type;
  }
};

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getDuration = (timestamp, showYearWithMonth = false) => {
  // compute the delta between the
  // provided timestamp and the current timestamp and display
  // the result in human readable format

  const unixTimestamp = timestamp * 1000;
  const date = new Date(unixTimestamp);
  const currentDate = new Date();
  const currentTimestamp = Math.round(currentDate.getTime() / 1000);
  const seconds = currentTimestamp - timestamp;
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.floor(hours / 24);

  if (date.getFullYear() !== currentDate.getFullYear()) {
    return `${date.getDate()} ${MONTHS[date.getMonth()]}, ${date.getYear() % 100}`;
  }

  if (days > 6) {
    return `${new Date(unixTimestamp).getDate()} ${MONTHS[date.getMonth()]}${showYearWithMonth ? `, ${date.getFullYear() % 100}` : ""}`;
  }

  if (days > 0) {
    return days + "d";
  }

  if (hours > 0) {
    return hours + "h";
  }

  if (minutes > 0) {
    return minutes + "m";
  }

  if (seconds > 0) {
    return "Now";
  }
};

export const getFormattedDate = (timestamp) => {
  if (timestamp) {
    return new Date(timestamp * 1000).toUTCString();
  }
};

/**
 * Converts a Unix timestamp (in seconds) to a formatted date and time string.
 * The returned string is in the format "hh:mm AM/PM · Month DD, YYYY".
 *
 * @param {number} unixTimestamp - The Unix timestamp to convert, in seconds.
 * @returns {string} The formatted date and time string.
 */
export const formatUnixTimestamp = (unixTimestamp, hideYear = false) => {
  try {
    const date = new Date(unixTimestamp * 1000);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
    }).format(date);
    if (hideYear) return `${formattedTime} · ${formattedDate.split(",")[0]}`;
    return `${formattedTime} · ${formattedDate}`;
  } catch (error) {
    Sentry.captureException(error);
    return "";
  }
};

/** gives date in format of Jul 20, 2021 */
export function getShortMonthDayYearDate(timestamp) {
  const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export const getTrimmedString = (str, length) => {
  //TODO: The following formatting should be handled on the backend and we'll receive e.g. args: {value: {value: 3.8657299849302277e+21, display_value: "3.8657299849302277e+21"}}
  if (typeof str === "string") {
    if (length) {
      return str?.length > length ? str?.substring(0, length) + "..." : str;
    } else if (str?.startsWith("0x")) {
      return getTrimedAddress(str);
    }
    // fallback i.e. if length not specified / doesn't start with 0x then trim 40 characters by default
    return str?.length > 40 ? str?.substring(0, 40) + "..." : str;
    // avoid scientific notation for large numbers
  } else if (typeof str === "number") {
    const rounded = parseFloat(fixDecimalPlaces(str, 2));
    if (str >= 1e7 || str < 1e-3) return str.toExponential(2);
    else if (str > 1e3 || str < 1e7) return rounded.toLocaleString();
    else return rounded;
  } else if (typeof str === "boolean") {
    return JSON.stringify(str);
  }
  return str;
};
// avoid displaying the scientific notation of a large number
export const scientificNotationToInt = (number) => {
  if (number) {
    return new Intl.NumberFormat("en-IN", { useGrouping: false })?.format(
      number
    );
  }
};
export const fixDecimalPlaces = (number, places) => {
  // if number is float with decimal places more than specific places
  // only get specific decimal places
  if (!Number.isInteger(number)) {
    const decimalPlaces = number?.toString().split(".")[1]?.length || 0;
    if (decimalPlaces > places) {
      return number?.toFixed?.(places);
    }
  }
  return number;
};

export const weiToMainToken = (wei, tokenName) => {
  const number = scientificNotationToInt(wei);
  const token = typeof tokenName === "object" ? tokenName[0] : tokenName;
  let ethResult = parseFloat(Web3.utils.fromWei(number || "0", "ether"));
  ethResult = fixDecimalPlaces(ethResult, 2);

  return `${ethResult} ${token}`;
};

export const debounceFunction = (func, delay = 300) => {
  let timer;
  return function () {
    let self = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(self, args);
    }, delay);
  };
};

export const getAvatar = (twitter_profile, avatar) => {
  if (avatar) {
    return avatar;
  } else if (twitter_profile) {
    // Ideally should always be handled by the backend.
    // TODO(Shaheer): Please report endpoints where twitter profile exists but image source didn't..
    const handle = twitterUrlToHandle(twitter_profile);
    const url = `https://unavatar.io/twitter/${handle}`;
    return url;
  } else return defaultProfileImage;
};

export const stopEventPropagation = (e) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const stripTags = (str) => {
  if (str) {
    return str?.replace(/<[^>]+>/g, "");
  }
  return "";
};
export const uniqueObjectsInArray = (array) => {
  if (array.length) {
    return [...new Map(array?.map((item) => [item?.id, item])).values()];
  }
};

export const groupBy = function (array, key) {
  if (array?.length) {
    return array.reduce((group, item) => {
      const instance = item[key];
      // if group instance already exists get it's content else create a new group instance
      group[instance] = group[instance] || [];
      group[instance].push(item);
      return group;
    }, {});
  }
  return false;
};

export const handleUrls = (unprocessedBio) => {
  if (unprocessedBio === null || unprocessedBio === undefined) return "";

  if (typeof unprocessedBio !== "string") {
    return unprocessedBio;
  }

  var urlRegex = /(https?:\/\/[^\s<]+)(?![^<]*>|[^<>]*<\/a>)/g;

  return unprocessedBio.replace(urlRegex, function (url) {
    return `<a href="${url}" rel="noreferer" target="_blank" >${url} </a>`;
  });
};
export const handleTwitterHandles = (semiProcessedBio) => {
  const stripedBio = stripTags(semiProcessedBio);

  if (stripedBio?.length) {
    var twitterHandleRegex = /@[A-Za-z0-9_]+/g;
    return stripedBio.replace(twitterHandleRegex, function (handle) {
      if (handle.length > 16) return handle; // not a valid twitter handle
      const handleName = handle.slice(1);
      return `<a target="_blank" href="http://twitter.com/${handleName}">${handle}</a>`;
    });
  }
};
export const twitterUrlToHandle = (url) => {
  if (url) {
    const splits = url.split("/");
    const handle = splits[splits?.length - 1];
    return handle?.replace("@", "");
  }
};
export const getFormattedText = (text) => {
  return handleUrls(handleTwitterHandles(stripTags(text)));
};

export const getPathMetaTags = (path) => {
  if (path?.length) {
    let metaTags = {
      title: "",
      properties: {
        description: "Decentralized social network for crypto natives",
        keywords:
          "social network, decentralized, blockchain, ethereum, activities, feeds",
      },
    };
    if (path.includes("media")) {
      metaTags.title = "Media Preview";
      metaTags.description =
        "You can follow top crypto people, your friends, or people who you have interacted with on the blockchain.";
    } else if (path.includes("feed")) {
      metaTags.title = "Feed";
      metaTags.description =
        "Most interesting blockchain activity based on followed people";
    } else if (path.includes("people")) {
      metaTags.title = "People";
      metaTags.description =
        "You can follow top crypto people, your friends, or people who you have interacted with on the blockchain.";
    } else if (path.includes("profile")) {
      metaTags.title = "Setup Profile";
      metaTags.description = "Setup your profile and follow interesting people";
    } else if (path.includes("messages")) {
      metaTags.title = "Messages";
      metaTags.description = "Connect with people behind the ETH addresses";
    } else if (path.includes("activity_details")) {
      metaTags.title = "Activity Details";
      metaTags.description =
        "View activity details, transactions, logs, internal calls, ...";
    } else if (path.includes("person_details")) {
      metaTags.title = "Person Details";
      metaTags.description =
        "View transactions, balances, token & NFT tokens holdings, ...";
    } else if (path.includes("contract_details")) {
      metaTags.title = "Contract Details";
      metaTags.description =
        "View contract transactions, balances, token & NFT tokens holdings, ...";
    }
    return metaTags;
  }
};

export const abbreviateNumber = (number, format = false) => {
  if (number == null) {
    return number;
  }

  const absNumber = Math.abs(Number(number));

  if (absNumber >= 1000000000000) {
    return Number.parseFloat(number).toExponential(2);
  }

  if (absNumber >= 1000) {
    const lookup = [
      { value: 1e9, symbol: "B" },
      { value: 1e6, symbol: "M" },
      { value: 1e3, symbol: "K" },
    ];
    const trailingZeroes = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const matchedLookup = lookup.find(function (matchedLookup) {
      return absNumber >= matchedLookup.value;
    });

    let toFixed = 0;

    if (absNumber / matchedLookup?.value < 10) {
      toFixed = 2;
    } else {
      if (absNumber / matchedLookup?.value < 100) {
        toFixed = 1;
      }
    }

    const abbreviated = (number / matchedLookup.value)
      .toFixed(toFixed)
      .replace(trailingZeroes, "$1");

    if (format)
      return (
        parseFloat(abbreviated).toLocaleString("en-US") + matchedLookup.symbol
      );
    else return abbreviated + matchedLookup.symbol;
  }
  if (absNumber >= 100) {
    return fixDecimalPlaces(number, 0);
  }
  if (absNumber >= 10) {
    return fixDecimalPlaces(number, 1);
  }

  if (absNumber === 0) {
    return "0";
  }
  if (absNumber < 0.001) {
    let exponent = Number.parseFloat(number).toExponential(3);
    let exponentParts = exponent.split("e");
    let power = exponentParts[1];
    let zerosCount = Math.abs(power) - 1;
    let subscript = zerosCount
      .toString()
      .split("")
      .map((digit) => {
        const subscripts = ["₀", "₁", "₂", "₃", "₄", "₅", "₆", "₇", "₈", "₉"];
        return subscripts[digit];
      })
      .join("");

    let nonZeroPart = exponentParts[0].replace(".", "");
    return `0.0${subscript}${nonZeroPart}`;
  }
  if (absNumber < 0.01) {
    return fixDecimalPlaces(number, 5);
  }
  if (absNumber < 0.1) {
    return fixDecimalPlaces(number, 4);
  }
  return fixDecimalPlaces(number, 3);
};

export const searchInArray = (array, columns, searchTerm) => {
  if (array?.length) {
    const result = array
      // create a copy of object to avoid mutating the original array
      .map(({ ...item }) => {
        let matched = false;
        // logs specific i.e. matched results count for parsed/unparsed
        let totalMatched = { unparsed: 0, parsed: 0 };
        columns.forEach((col) => {
          const needle = searchTerm?.toLowerCase();
          let stock;
          if (typeof item[col] === "object") {
            // if property is array
            if (item[col]?.length) {
              stock = item[col]?.toString();
            }
            // else if property is object
            else {
              // temporary, if type of value is number, change from scientific notation to int
              // should be removed when all numbers are passed as strings from the backend

              // convert object to array of key value
              const numberToString = Object.entries(item[col])
                ?.map(([key, val]) => {
                  return {
                    [key]:
                      typeof val === "number"
                        ? scientificNotationToInt(val)
                        : val,
                  };
                })
                // convert array of objects to dictionary
                .reduce((result, filter) => {
                  return { ...result, ...filter };
                }, {});
              stock = JSON.stringify(numberToString);
            }
          } else if (typeof item[col] === "number") {
            stock = item[col]?.toString();
          } else {
            stock = item[col];
          }
          stock = stock?.toLowerCase();
          if (stock?.includes(needle)) {
            matched = true;

            // highlight the needle in property
            // skip html tags
            // const regex = new RegExp(`(?<!<[^>]*)${needle}`, "g");
            // let highlighted = stock?.replaceAll(
            //   regex,
            //   `<span ${
            //     // if object, escape " to avoid errors during JSON.parse
            //     propertyType === "object"
            //       ? `class=\\"matched\\"`
            //       : `class="matched"`
            //   } >${needle}</span>`
            // );

            // if (propertyType === "array") {
            //   // if property type is array split by "," since it
            //   // was converted to string previously
            //   highlighted = highlighted?.split(",");
            // }

            if (matched) {
              // if (["array", "number", "string"].includes(propertyType)) {
              //   item[col] = highlighted;
              // } else if (propertyType === "object") {
              //   item[col] = JSON.parse(highlighted);
              // }

              // logs specific i.e. matched results count for parsed/unparsed
              if (["data", "topics"].includes(col)) {
                totalMatched.unparsed += 1;
              } else if (["event", "args"].includes(col)) {
                totalMatched.parsed += 1;
              }
            }
          }
        });
        item["total_matched"] = totalMatched;
        return matched ? item : null;
      })
      ?.filter((item) => item);

    return result;
  }
};

export const getChainMetaData = (chainId) => {
  if (typeof chainId === "string") {
    return chainMetaData?.find((item) => item?.name === chainId);
  } else if (typeof chainId === "object" && chainId?.length) {
    const availableChains = chainMetaData?.filter((item) =>
      chainId?.includes(item?.name)
    );
    availableChains?.unshift({
      name: "",
      icon: walletIcon,
      overlayIcon: null,
    });
    return availableChains;
  }
  return chainMetaData;
};

export const getChainMetaDataWithProfile = ({ chainId, chainProfiles }) => {
  if (chainProfiles?.[chainId]) {
    const data = chainProfiles[chainId];
    return {
      name: data.name,
      icon: data.logo_uri || data.logo,
      overlayIcon: data.logo_uri || data.logo,
      website: data.explorers?.[0]?.kind ?? "Explorer",
    };
  }
  return getChainMetaData(chainId);
};

export const getRecentActivities = (existingActivities, fetchedActivities) => {
  if (existingActivities?.length && fetchedActivities?.length) {
    const existingActivitiesIds = map(existingActivities, "id");
    let activities = [];
    existingActivities.forEach((existing) => {
      fetchedActivities.forEach((fetched) => {
        if (
          !existingActivitiesIds.includes(fetched.id) ||
          (existing.id === fetched.id &&
            fetched.tx_ids?.length > existing.tx_ids?.length)
        )
          activities.push(fetched);
      });
    });
    return uniqBy(activities, "id");
  }
};

export const calculateBalance = (balance, decimals) => {
  if (balance && decimals) return balance / 10 ** decimals;
};

export const calculateChangePercentage = (a, b) => {
  let percent;
  if (b !== 0)
    if (a !== 0) percent = ((b - a) / a) * 100;
    else percent = b * 100;
  else percent = -a * 100;

  return Math.floor(percent);
};

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export const referenceTimeAgo = ({ date, type = "trimmed", referenceTime }) => {
  const seconds = Math.floor((referenceTime - new Date(date)) / 1000);
  const YEAR_IN_SECONDS = 60 * 60 * 24 * 365;
  const MONTH_IN_SECONDS = 60 * 60 * 24 * 30;
  const DAY_IN_SECONDS = 60 * 60 * 24;
  const HOUR_IN_SECONDS = 60 * 60;
  const MINUTE_IN_SECONDS = 60;

  const intervals = [
    { label: type === "full" ? " years" : "y", seconds: YEAR_IN_SECONDS },
    { label: type === "full" ? " months" : "mo", seconds: MONTH_IN_SECONDS },
    { label: type === "full" ? " days" : "d", seconds: DAY_IN_SECONDS },
    { label: type === "full" ? " hours" : "h", seconds: HOUR_IN_SECONDS },
    { label: type === "full" ? " minutes" : "min", seconds: MINUTE_IN_SECONDS },
    { label: type === "full" ? " seconds" : "s", seconds: 1 },
  ];

  const interval = intervals.find((interval) => seconds >= interval.seconds);
  if (interval) {
    const count = Math.floor(seconds / interval.seconds);
    const label =
      count === 1 && type === "full"
        ? interval.label.slice(0, -1)
        : interval.label;

    return `${count}${label} ${type === "full" ? "ago" : ""}`;
  }

  return "just now";
};

export const timeAgo = (date, type = "trimmed") => {
  const now = new Date();
  return referenceTimeAgo({ date, type, referenceTime: now });
};

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const getImage = (path) => {
  if (!(path && typeof path === "string")) {
    return require("assets/png/unknown_token_logo.png").default;
  }

  if (path?.includes("://"))
    return path; // External image
  else if (path?.startsWith("/"))
    return path; // Static image deployed with the webapp
  else if (path.startsWith("assets/")) return remapAssets(path);
};
export const getLocallyHostedImage = (path) => {
  if (!path) {
    return null;
  }
  if (!path?.startsWith("assets/")) {
    return path;
  }

  // Static image deployed with the webapp
  return remapAssets(path);
};

export const getPersonHovercard = (person, copyable = true) => {
  if (person?.profile_type !== "contract")
    return (
      <ProfileHoverCard actor={person}>
        <Link to={person?.link}>
          <ValueType
            copyable={copyable}
            value={person?.display_name}></ValueType>
        </Link>
      </ProfileHoverCard>
    );
  else
    return (
      <Link to={person?.link}>
        <ValueType copyable={copyable} value={person?.display_name}></ValueType>
      </Link>
    );
};

export const hideNegativeDisplayValue = (display_value) => {
  if (!display_value.includes("-")) return display_value;
};

export const fallbackAvatar = (profile) => {
  return `https://ui-avatars.com/api/?background=0648d7&color=fff&name=${
    profile?.display_name || profile?.address?.split("0x")[1]
  }`;
};

/**
 * Workaround for response sent via search-ens-names until response format is fixed
 * @param {object} people
 * @returns {object}: Reformatted dict understandable by PeopleItem component
 */
export const transformForPeopleItem = (people) => {
  let newPeople = { ...people };

  newPeople.address = newPeople.address || newPeople.resolved_address;

  if (newPeople.link) {
    if (newPeople.link.includes("identity")) {
      newPeople.identity_link = newPeople.link;
      newPeople.id = newPeople.identity_id;
    }
  } else {
    newPeople.link = `/${newPeople.resolved_address}`;
  }
  return newPeople;
};

export const protocolTypeToLabel = (key) => {
  let label = key;
  switch (key) {
    case "DEX":
      label = "Liquidity Pool";
      break;
    case "PerpetualPosition":
      label = "Perpetuals";
      break;
    case "TokenExpansion":
      label = "Deposit";
      break;
    case "ContractDeposit":
      label = "Deposit";
      break;
    case "Lending":
    case "LeveragedFarming":
      label = "Lending";
      break;
    case "LiquidStaking":
      label = "Liquid Staking";
      break;
    default:
      break;
  }
  return label;
};
const getTypeFromExtension = (extension) => {
  if (!extension) return null;

  extension = extension.toLowerCase();

  if (["png", "jpg", "jpeg", "gif", "svg"].includes(extension)) {
    return "image";
  }

  if (["mp4", "mov", "webm"].includes(extension)) {
    return "video";
  }
};

export const getMediaType = (media) => {
  let extension = "";
  if (!media) return null;

  if (media?.includes("ipfs://") && !media?.match(/\.\w{3,4}($|\?)/))
    return "ipfs";

  if (media.startsWith("data:")) {
    //get extension from base64
    const extension = media?.split(";")[0]?.split("/")[1];
    return getTypeFromExtension(extension);
  }

  //get extension from url
  extension = media?.split(".").pop();
  return getTypeFromExtension(extension);
};

export const transformedIPFSLink = (link) => {
  if (!link) return null;
  if (link.includes("ipfs://")) {
    return link.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/");
  }
};

export const getKeysCountInObject = (object) => {
  return Object.keys(object).length;
};

export const getFirstNonEmptyKeyValueFromObject = (object) => {
  const entries = Object.entries(object); // Get entries of the object

  let index = 0;

  while (index < entries.length && entries[index][1].length === 0) {
    index++; // Skip entries with value length of 0
  }

  return entries[index] || []; // Return the first non-zero length entry, or an empty array if none found
};

export const epochToReadable = (epoch) => {
  if (!epoch) return "";
  const date = new Date(epoch * 1000); // convert to milliseconds
  const year = date.getFullYear().toString().slice(-2); // get last 2 digits of year
  const month = date.toLocaleString("default", { month: "short" }); // get month abbreviation
  const day = date.getDate().toString().padStart(2, "0"); // add leading zero if necessary
  const now = new Date();
  const diff = (now.getTime() - date.getTime()) / (1000 * 3600 * 24 * 365); // get difference in years
  const currentYear = now.getFullYear().toString().slice(-2); //get last 2 digits of current year
  if (diff < 1 && year === currentYear) {
    return `${day} ${month}`;
  } else {
    return `${day} ${month} ${year}`;
  }
};

export const getTimeString = (timestamp) => {
  const dateObj = new Date(timestamp * 1000); // Convert timestamp to milliseconds
  const timeString = dateObj.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return timeString;
};
export const conditionalClass = (condition, className) => {
  if (condition) {
    return className;
  }
};

export const combinedMediaItems = (groupedActivity) => {
  if (!groupedActivity?.activities) {
    return [];
  }

  const mediaArray = uniq(flatMap(groupedActivity.activities, "media"));

  return mediaArray;
};
export const combinedGnosisSigners = (groupedActivity) => {
  if (!groupedActivity?.activities) {
    return [];
  }
  if (
    groupedActivity?.activities[0]?.addresses_involved?.mulitisig_signatories ==
    null
  ) {
    return [];
  }

  const signersArray = uniq(
    flatMap(
      groupedActivity.activities,
      "addresses_involved.mulitisig_signatories"
    )
  );

  return signersArray;
};

export const removePlusOrMinusFromPriceChange = (price) => {
  if (!price) return null;
  return price.replace("+", "").replace("-", "");
};

export const getContractNameAndLogo = (profile) => {
  if (!profile) return null;
  if (
    profile?.address_type === "NFT" ||
    profile?.address_type === "MultiToken" ||
    profile?.address_type === "HybridToken"
  ) {
    return {
      name: profile?.protocol_details?.name || profile?.display_name,
      logo: profile?.protocol_details?.icon || profile?.display_picture,
    };
  } else {
    return {
      name: profile?.display_name,
      logo: profile?.display_picture,
    };
  }
};

export const truncateText = (text) => {
  if (!text) {
    return null;
  }
  return `${text?.slice(0, 15)}${text?.length > 15 ? "..." : ""}`;
};

export const isEngagement = ({ engagement_type }) => {
  if (["likes", "bookmarks", "reposts"].includes(engagement_type)) {
    return true;
  }
  return false;
};

export const ancestorsToQueryKeys = (ancestors) => {
  if (!ancestors) {
    return [];
  }
  /**
   * get the parent and grand parent posts and transform array of objects to query keys
   * e.g.
   * [
   *  {
   *    id: 54,
   *    type: "post"
   *  },
   * {}, {}, ...
   * ]
   *
   * will be transformed into
   *
   * [
   *  ["groupedA", 54, "post"],
   *  [], [], ...
   * ]
   *
   */

  const slicedAncestors = ancestors?.slice(0, 2);
  return slicedAncestors?.map((obj) => [
    ...transformGroupedActivityQuery(obj.id, obj.type),
  ]);
};

export const fetchQueryWithPartialKeys = ({ queryKeys, queryClient }) => {
  if (!queryClient || !queryKeys) {
    return;
  }

  queryKeys.forEach((queryKey) => {
    /**
     * queryKey is a partial react query key
     * to match it with actual query key:
     * 1. get all queries data that match the partial key
     * 2. the 0th index in the response of getQueriesData is the actual query key
     * 3. to prevent invalid queryFn error, we get id and type and pass the queryFn
     *
     * Note: another option is to use refetchQueries, which refetches queries even if we
     * have a partial key. the issue with that is that it can't refetch disabled queries
     * i.e. single feed item
     *
     */
    const data = queryClient.getQueriesData({ queryKey });
    const returnedQueryKeys = data.map((item) => item?.[0]);

    returnedQueryKeys.forEach((returnedKey) => {
      const [, id, type] = returnedKey;

      queryClient.fetchQuery({
        queryKey: returnedKey,
        queryFn: () => getGroupedActivity({ id, type }),
      });
    });
  });
};

export const handleFeedItemClick = ({
  history,
  event,
  noRedirection = false,
  clickable = true,
  contentId,
  contentType,
  link,
  repliesCount,
  activityId,
  token_address = null,
  chain_id = null,
}) => {
  let path = ``;
  if (link) {
    if (!history) {
      return;
    }
    path = link;
  } else {
    if (!history || !clickable || !contentId || noRedirection) return;
    const currentTab =
      contentType !== "activity_group" || repliesCount
        ? "comments"
        : "transactions";
    if (contentType === "news" && chain_id && token_address) {
      path = `/news_details/${chain_id}/${token_address}/transactions`;
    } else if (contentType === "transaction") {
      path = `/activity_details/${activityId}/details`;
    } else path = `/${contentType}/${contentId}/${currentTab}`;
    if (link) {
      path = link;
    }
  }

  if (event?.metaKey || event?.ctrlKey) {
    window.open(path, "_blank");
  } else {
    history.push({
      pathname: path,
    });
  }
};

export const addTabToTabsList = ({
  tabsList,
  condition,
  tabDetails,
  prepend = false,
}) => {
  if (!tabsList) return;

  return condition
    ? prepend
      ? [tabDetails, ...tabsList]
      : [...tabsList, tabDetails]
    : tabsList;
};

export const getDateRangesForStartAndEndEpoch = (start, end) => {
  const startDate = new Date(start * 1000);
  const endDate = new Date(end * 1000);

  let range;
  if (isSameYear(startDate, endDate)) {
    if (isSameMonth(startDate, endDate)) {
      if (isSameDay(startDate, endDate)) {
        range = `${formatDate(startDate)}`;
      } else {
        range = `${startDate.getDate()} - ${formatDate(endDate)}`;
      }
    } else {
      range = `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }
  } else {
    range = `${formatDate(startDate, true)} - ${formatDate(endDate, true)}`;
  }

  return range;
};

const isSameDay = (startDate, endDate) =>
  startDate.getDate() === endDate.getDate() &&
  isSameMonth(startDate, endDate) &&
  isSameYear(startDate, endDate);

const isSameMonth = (startDate, endDate) =>
  startDate.getMonth() === endDate.getMonth();

const isSameYear = (startDate, endDate) =>
  startDate.getFullYear() === endDate.getFullYear();

const formatDate = (date, formatYear) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = formatYear ? `, ${date.getFullYear()}` : "";
  return `${day} ${month}${year}`;
};

export const transformGroupedActivityQuery = (
  id,
  type,
  timestamp = "",
  isFromBlob = true
) => {
  const queryConstant = isFromBlob
    ? QueryKeyConstants.GROUPED_ACTIVITY_FROM_BLOB
    : QueryKeyConstants.GROUPED_ACTIVITY;
  if (!id || !type) {
    return [];
  }
  return [
    queryConstant,
    id.toString(),
    type,
    ...(!!timestamp ? [timestamp] : []),
  ];
};

export const isShowingQrCode = (location) => {
  if (
    location.pathname === "/explorer" ||
    location.pathname.startsWith("/explorer/")
  ) {
    return false;
  } else if (
    (location.pathname === "/screener" ||
      location.pathname.startsWith("/screener/")) &&
    location.search
  ) {
    return false;
  }
  return true;
};

export const removePercentSign = (percent) => {
  return percent.substring(0, percent.length - 1);
};

export const getProfileImageType = ({ profile }) => {
  if (profile?.isToken) {
    return IMAGE_TYPES.TOKEN;
  }
  if (profile?.address_type === "contract") {
    return IMAGE_TYPES.CONTRACT;
  }

  if (profile?.address_type === "NFT") {
    return IMAGE_TYPES.NFT;
  }

  return IMAGE_TYPES.AVATAR;
};

export const removeQuotesFromText = (text) => {
  const quotesRegex = /^["']|["']$/g;

  const result = text.replace(quotesRegex, "");

  return result;
};
export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return MobileOSTypes.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return MobileOSTypes.IOS;
  }
  return MobileOSTypes.UNKNOWN;
};

export const isJoinWaitlist = (location) => {
  return (
    location.pathname === "/join-waitlist" ||
    location.pathname.startsWith("/join-waitlist/")
  );
};

export const updateQueryParams = ({ location, history, updates }) => {
  const params = location.search
    ? location.search
        .slice(1)
        .split("&")
        .reduce((result, value) => {
          const parts = value.split("=");
          if (parts[0])
            result[parts[0]] = parts[1] ? decodeURIComponent(parts[1]) : "";
          return result;
        }, {})
    : {};

  for (const [paramName, newParamValue] of Object.entries(updates)) {
    const currentParamValue = params[paramName];
    // if param already exists and we receive an empty value, remove it
    if (
      paramName &&
      (!newParamValue || newParamValue?.length === 0) &&
      currentParamValue
    ) {
      delete params[paramName];
    } else if (newParamValue) {
      // If param already exists update it, If it doesn't exist, add it
      params[paramName] = newParamValue;
    }
  }

  const encodedParams = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");

  // Update the URL with the new query params
  history.push(`${location.pathname}?${encodedParams}`);
};

export const parseFilterParams = (queryString) => {
  if (!queryString) {
    return [];
  }

  const tokensArray = queryString.split(",");

  let tokensObjectArray = [];
  for (let i = 0; i < tokensArray.length; i += 2) {
    let tokenObject = {
      contract_address: tokensArray[i],
      chain_id: tokensArray[i + 1],
    };
    tokensObjectArray.push(tokenObject);
  }

  return tokensObjectArray;
};

export const transformFilterParams = (list) => {
  if (!list) {
    return;
  }

  return list
    ?.map((item) => `${item.contract_address},${item.chain_id}`)
    .join(",");
};

export const addCommasToNumber = (number) => {
  if (!number) {
    return null;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getCssVariable = (variableName) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim();
};

export const lowercaseObjectKeys = (obj) => {
  if (!obj) return obj;
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[key.toLowerCase()] = obj[key];
  });
  return newObj;
};

export const cutStringBeyondLength = ({
  text,
  length,
  dotsCount = 3,
  suffixLength = 0,
}) => {
  if (!text) return text;
  if (text.length > length) {
    const lengthWithoutDots = length - dotsCount;
    const startingCharsCount = lengthWithoutDots - suffixLength;
    // Ensure all lengths are positive
    if (
      lengthWithoutDots < 0 ||
      startingCharsCount < 0 ||
      dotsCount < 0 ||
      suffixLength < 0
    ) {
      Sentry.captureMessage(
        `cutStringBeyondLength: Negative length values provided. lengthWithoutDots: ${lengthWithoutDots}, startingCharsCount: ${startingCharsCount}, dotsCount: ${dotsCount}, suffixLength: ${suffixLength}, text: ${text}`
      );
      return text;
    }
    const startingChars = text.slice(0, startingCharsCount);
    const tailingChars = suffixLength > 0 ? text.slice(-suffixLength) : "";
    return startingChars + ".".repeat(dotsCount) + tailingChars;
  }
  return text;
};

export const iso8601TimestampToEpoch = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  let date = new Date(timestamp);
  let epoch = Math.floor(date.getTime() / 1000);
  return epoch;
};

export const hexToUtf8 = (hexStr) => {
  if (!isHex(hexStr)) {
    return hexStr;
  }
  try {
    return Web3.utils.hexToUtf8(hexStr);
  } catch (err) {
    return hexStr;
  }
};

export const isHex = (hexStr) => {
  return Web3.utils.isHex(hexStr);
};

export const getLocalTime = (originalTime) => {
  const d = new Date(originalTime * 1000);
  return (
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
      d.getMilliseconds()
    ) / 1000
  );
};

export const getTokenTransfers = (activity) => {
  return activity?.token_transfers ?? activity?.token_transfers_pnl ?? [];
};

export const isProfilePage = (path) => {
  const pathSplit = path.split("/");
  return (
    pathSplit.length > 2 &&
    [
      "overview",
      "activities",
      "portfolio",
      "nfts",
      "wallets",
      "deployed_contracts",
      "bundle",
    ].includes(pathSplit[2])
  );
};

export const getPlurals = (count, word) => {
  return count > 1 || count === 0 ? word + "s" : word;
};

export const getCommentCount = (post, engagements) => {
  const hasThreads = post?.threads?.length > 1;

  if (!post || !hasThreads) return engagements?.replies?.count ?? 0;

  const threadIds = [];
  post.threads.forEach((thread) => {
    threadIds.push(thread.id?.toString());
    threadIds.push(thread.blob_id);
  });
  return (
    engagements?.replies?.threads?.filter((eng) => {
      return !(
        threadIds.includes(eng.id?.toString()) &&
        threadIds.includes(eng.blob_id)
      );
    })?.length ?? 0
  );
};

export const shuffleArray = (array) => {
  if (!array || !array.length) return array;
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};
